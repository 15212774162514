<template>
  <div>
    <div
      class="d-flex flex-wrap align-center justify-space-between rounded mb-4"
      style="gap: 0.5rem"
      :class="{
        'white': !$vuetify.theme.dark,
        'grey darken-4': $vuetify.theme.dark,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="{
          'primary white--text': $route.name == item.route,
        }"
        class="py-2 px-4 rounded pointer text-center col-auto col-auto"
        @click="goToSection(item)"
      >
        <!-- <v-badge
          :content="getSectionNotificationCount(item)"
          :value="getSectionNotificationCount(item)"
          color="error"
          overlap
          offset-x="-5px"
        > -->
        <div class="d-flex justify-center">
          <v-chip
            v-if="getSectionNotificationCount(item)"
            color="error"
            class="me-1 rounded-pill px-2"
            small
          >
            {{ getSectionNotificationCount(item) }}
          </v-chip>
          {{ item.title }}
        </div>
        <!-- </v-badge> -->
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tab: null,
      items: [
        { title: this.$t('Info'), route: 'view-company.info' },
        { title: this.$t('Business Symbol'), route: 'view-company.business-symbol' },
        { title: this.$t('Shareholders'), route: 'view-company.shareholders' },
        { title: this.$t('Accounting'), route: 'view-company.accounting' },
        { title: this.$t('Attachments'), route: 'view-company.attachment' },
        { title: this.$t('Seasonal & Yearly Form'), route: 'view-company.seasonal-form', notifyKey: 'seasonal_form' },
        { title: this.$t('Residence'), route: 'view-company.residence' },
        { title: this.$t('Social Security'), route: 'view-company.social-security', notifyKey: 'social_security' },
        { title: this.$t('Lawyer Contract'), route: 'view-company.lawyer-contract', notifyKey: 'lawyer_contract' },
      ],
      viewData: {},
    }
  },

  computed: {
    ...mapGetters([
      'companiesNotification',
    ]),
  },

  mounted() {
    this.getViewData().then(() => {
      this.$_section_title({ title: this.viewData.name })
    })
  },

  methods: {
    getSectionNotificationCount(item) {
      if (!item.notifyKey) return 0

      const found = this.companiesNotification[`${item.notifyKey}`].filter(el => el.company_id == this.viewData.id)
      return found.length
    },

    goToSection(item) {
      this.$router.push({ name: item.route })
    },

    async getViewData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>